/* .feedbackWrapper input.w-100.message{
    padding-bottom: 10em;
} */

.feedbackWrapper form input,
.feedbackWrapper form button,
.feedbackWrapper form textarea{
    border-radius: 0 !important;
}
.feedbackWrapper h2{
    font-size: 2.5em;
}
.feedbackWrapper aside{
    /* margin: 200px 0; */
    margin-top: 12vh;
    padding: 6.5em 0;
}
.feedbackWrapper form{
    margin-top: -15px;
}
.feedbackWrapper button{
    border: solid 1px whitesmoke;
    padding: 6px 35px;
}
.feedbackWrapper button:hover{
    border: solid 1px black;
}