@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Comfortaa:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');

.App {
  text-align: center;


}
header {
  font-family: 'Abril Fatface', cursive;
  font-family: 'Comfortaa', cursive;
  padding-top: 1vh;
}
.headerCon {
  position: relative;
}
header hr {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  color: #343A40 !important;
  background-color: rgba(52, 58, 64, 0.212);
  opacity: 1;
}

header h1 {
  font-size: 1.5vw;
  letter-spacing: 0.135vw;
}

header h2 {
  font-size: .8vw;
  letter-spacing: 0.31vw;
  padding-left: 3px;
}




body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300 !important;

}

body .wrapper {
  /* margin-top: 10vh; */
  /* margin-bottom: 20vh; */
}

body .wrapper h2 {
  font-weight: 300 !important;
  margin: 2em 0.1em;
  text-align: left;
  line-height: 2em;
  color: #707070;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.wrapper .input input {
  margin: 1.3em 0em;
  border: none;

  width: 100%;
  padding-left: 1em;
  padding-bottom: 1em;
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  border-bottom: 1px solid rgb(94, 182, 147);
}

.wrapper .input button {
  background-color: rgb(94, 182, 147);
  box-shadow: 0px 0px 18px 0px rgba(223, 223, 223, .6);
  color: white;
  margin-top: 1em;
  border: none;
  padding: .5em 2em;
  border-radius: 30px;
  font-size: larger;
  letter-spacing: 3px;
  font-weight: 500;
  outline-style: none;
  transition: .3s;
}

.wrapper .input button:hover {
  background-color: rgb(69, 136, 109);

}

.growthrateInput input{
  padding: 0 !important;
  border: #282c34 solid 2px;


}


.wrapper .input .col label{
  position: absolute;
  pointer-events: none;
  top: 30px;
  left: 10%;
  color: #999;
  transition: .5s;

}

.wrapper .input input:focus~label,
.wrapper .input input:valid~label{

  top: 1%;
  left: 5%;
  color: rgb(94, 182, 147);
  font-size: 90%;
  font-weight: 500;
}


.chart-container{
  height: 40vh;
  /* height: 800px; */
}



.wrapper .output {
  box-shadow: 0px 0px 18px 0px rgb(223, 223, 223);
  padding: 3.4em;
  text-align: left;
}

.wrapper .output div {
  margin: 1.2em 0em;
}

.wrapper .growthrateInput input {
  background-color: #282c34 !important;
  color: #282c34 !important;
}
#test{
  /* position: relative;
  */
  /* padding-bottom: -100px !important;  */
}
#test input:valid~label {
  top: -5%;
  left: 10%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#footer{
  background-color: black;
}
@media only screen and (max-width: 992px) {
  body .output {
    margin-top: 5vh;
  }

  body h2 {
    margin-top: 0 !important;
  }
}